@import "./colors/red_button.scss";
@import "./colors/white-button.scss";
@import "./colors/black-button.scss";
.button{
    &.spinanico-button{
        transition-duration: 0.52s;
        padding: 10px 24px;
        border-radius: 5px;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.03), 0px 2px 4px rgba(0, 0, 0, 0.08);
        color:#333;
        border:1px;
        font-weight: bold;
        cursor: pointer; cursor: hand;

        &.white-button{ @include whiteButton();}
        &.red-button{@include redButton();}
        &.black-button{@include blackButton();}
        &:hover{
            transition-duration: 0.3s;
        }
        &:active{
            transition-duration: 0.3s;

        }
    }
}