
.container-of-skill{
    max-width: 800px;
    margin-top: 15vh;
}
.skill{
    width: 70px;
    margin: 15px;
    height: 70px;
    border-radius: 50%;
    
    background-color: #fff;
    text-align: center;
    box-shadow: 
    0px 0px 5px rgba(0, 0, 0, 0.08),
    0px 0px 15px rgba(0, 0, 0, 0.10),
    
    ;

    background-size: 70% auto;
    background-position: center;
    background-repeat: no-repeat;

    &.flutter{
        background-image: url(./assets/skills/flutter.png);
    }
    &.go{
        background-image: url(./assets/skills/go.png);
    }

    &.python{
        background-image: url(./assets/skills/python.png);
        background-color: #1e415d;
    }
    &.javascript{
        background-image: url(./assets/skills/javascript.png);
        background-color: #f8dc3d;
    }
}

// .container-skills{
//     background-color: #fff;
//     display: grid;
//     grid-template-columns:  repeat(auto-fit, minmax(300px, 1fr));
//     grid-template-rows:  auto;

//     .skills-grid{
        
//         //display: grid;
//         width:90%;
//         position: relative;
//         z-index:999;
//         // grid-template-columns: repeat(auto-fit, 180px);
//         // grid-template-rows:  auto;
//         // grid-column-gap:  30px;
//         // grid-row-gap:  30px;
//         margin:auto;
//         text-align: center;


//         .no-home-skills{
//             height: auto;
//             .fixed-bar{
//                 height: 40px;
//                 text-align: center;
//                 padding: 15px;
//             }
//             .wrap-skills{
//                 height: auto;
//                 overflow: hidden;
//                 transition-duration: 1.5s;
//                 &.open{
//                     height: auto;
//                     transition-duration: 0.5s;
//                 }
//                 &.close{
//                     height: 0px;
//                     transition-duration: 0.5s;
//                 }
//             }
//         }

//     }
//     .text-skills{
//         position: relative;
//         width: auto;
//         display: inline-block;
//         margin-left: 5%;
//         padding: 20px;
//         margin-bottom: 100px;
//     }
//     }