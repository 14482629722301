

.white-box{
    min-height: 100px;
    padding: 15px 5px 15px 5px;
    margin: 7px;
    margin-top: 35px;
    
    border-radius: 13.4px;
    box-shadow: 
    0px 2px 5px rgba(0,0,0,0.02), 
    0px 4px 15px rgba(0,0,0,0.04),
    0px 8px 22px rgba(0,0,0,0.06);
    color:#333;
    background-color: #fff;
    &.block{
        display: block;
        
    }
}

.container{
    background-color: #fff;
    display: inline-block;
    margin: 15px;
    padding: 0px;
    border-radius: 3px;
    box-shadow: 
    0px 0px 12px rgba(0,0,0,0.09),
    0px 4px 12px rgba(0,0,0,0.1),
    ;
}