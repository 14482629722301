
.book{
    .cover-book{
        width:50px;
        background-color: #f00;
    }

    .book-info{

    }
}