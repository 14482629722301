@mixin redButton(){
    background-color: rgb(216, 53, 53);

    color: #eee;
    &:hover{
        color: #fff;
        background-color:  rgb(173, 42, 42);
    }
    &:active{
        color:#fff;
        background-color:  rgb(189, 59, 59);
    }
}