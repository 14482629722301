
@mixin whiteButton(){

    color: #333;
    background-color: #f0f0f0;
    border-color: #ccc;
    &:hover{
        background-color:#e0e0e0;
        border-color: #e0e0e0;
    }
    &:active{
        background-color: #b0b0b0;
    }
}