.photo-cv{
    width:220px;
    height: 220px;
    border-radius: 50%;
    box-shadow: 0px 12px 30px rgba(0,0,0,.11);
    margin: 30px;
    background-image: url("./photo_2019-12-10_10-18-29.jpg");
    background-size: 100% auto;
    background-position: center;
    border: 3px solid #fff;
    -webkit-print-color-adjust: exact; 
}