
.window-header{
    height: 80vh;
    width: 100%;
    position: relative;
 
}


@mixin btnstick($dim){
    background-color: #fff;
    width:$dim; height: $dim;
    border: 1px solid #ccc;
    margin-top: -$dim; margin-left: -$dim;
    border-radius: 50%;
    top:100%; left:100%;
    position: relative;
    box-shadow: 0px 3px 13px rgba(0,0,0,.02);
}

@mixin dimension($d){
    width: $d;
    left:50%; top: 50%;
    margin-left: -$d/2;
    margin-top: -$d/2;
    height: $d;
}
.my-fuck-name{
    top: 100%;
    margin-top: 15px;
    height: 45px;
    width: 100%;
    text-align: center;
    position: absolute;
    font-size: 25px;
    line-height: 45px;
    font-weight: bold;
    background-color: #fff;
    border-radius: 9.4px;
    box-shadow: 
    0px 2px 13px rgba(0, 0, 0, 0.1),
    0px 5px 23px rgba(0, 0, 0, 0.1),
    0px 2px 23px rgba(0, 0, 0, 0.1),
    0px 2px 43px rgba(0, 0, 0, 0.2),
    ;
}
.my-fuck-face{
    position: absolute; 
    @include dimension(220px);
    background-image: url(./assets/my-fuck-face-3.jpg);
    background-size: 100% auto;
    background-repeat: no-repeat;
   
    background-position: center 6%;
    border-radius: 50%;
    box-shadow: 
    0px 2px 13px rgba(0, 0, 0, 0.2),
    0px 5px 23px rgba(0, 0, 0, 0.2),
    0px 2px 23px rgba(0, 0, 0, 0.2),
    0px 2px 43px rgba(0, 0, 0, 0.3),
    ;
    transition-duration: 0.5s;
        &.open{
            background-position: center 88%;
        }
        .stick-b{
            margin-top: 80px;
            top:100%;
            position: relative;
            background-color: #f00;
            height: 50px;
        }
    @media screen and (max-width: 400px) {
        transition-duration: 0.5s;
        @include dimension(150px);
    
        .my-fuck-name{font-size: 20px;}
    }

   
}