@mixin blackButton{
    background-color: #24292e;
    color:#ccc;
    &:hover{
        background-color: #2b3137;

    }
    &:active{
        background-color: #191c20;
        color:#fff;
    }
}