.book-box{
        margin-top: 50px;
        max-width: 780px;
        min-height: 200px;
        border-radius: 10px;
        padding: 12px  22px;
        margin: 20px;
        box-shadow: 0px 0px 15px rgba(0,0,0,0.08), 0px 0px 25px rgba(0,0,0,0.05);
        color: #fff;
        background: linear-gradient(90deg,
            #656bcf,
            #4b88cf,
            
        );
}
.book-entry-container{
    margin-top: 15vh;
}
.book-text{
    margin: 15px;
}