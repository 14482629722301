
.ground-spinanico{
    position: absolute;
    overflow: hidden;
    width:  100%;
    height: 100%;
    z-index: 1;
    .svg{
        position: absolute;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        background-image: url(./assets/groud.jpg);
        
        &.svg1{
            $dimension: 80vh;
            width:$dimension; height: $dimension;
            top:-10vw;
            left:-10vh;
            mask: url("./assets/path/2189644.svg");
            mask-size: contain;
            mask-repeat: no-repeat;
        }
        &.svg2{
            $dimension: 60vh;
            width:$dimension; height: $dimension;
            top:125vh; margin-top: -$dimension;
            left:100vw; margin-left: -$dimension;
            
            mask-image: url("./assets/path/2.svg");
            mask-size: contain;
            mask-repeat: no-repeat;
        }
        &.svg3{
            $dimension: 50vw;
            width:$dimension; height: $dimension;

            top:200vh; margin-top: -$dimension;
            left:0%;
            mask-image: url("./assets/path/spray-paint-banner-7.svg");
            mask-size: contain;
            mask-repeat: no-repeat;
        }

        &.svg4{
            $dimension: 80vw;
            width:$dimension; height: $dimension;
            top:100%; margin-top: -$dimension/2;
            mask-image: url("./assets/path/grop.svg");
            mask-size: 100% 100%;
            mask-repeat: no-repeat;
        }
        &.svg5{
            $dimension: 80vw;
            width:$dimension; height: $dimension;
            top:100%; margin-top: -$dimension;
            left:100%; 
            mask-image: url("./assets/path/grop.svg");
            mask-size: 100% 100%;
            mask-repeat: no-repeat;
        }
    }
}

.debug{
    border:1px dashed #f00;
}