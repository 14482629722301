

.lang-container{
    transition-duration: 0.5s;
    transform: scale(1);
    filter: blur(0px);
    &.blured{
        transform: scale(1.2);
        filter: blur(12.0px);
    }
}