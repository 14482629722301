.switch-language{
    width: 120px;
    height: 70px;
    margin-top: 10px;
    position: fixed;
    left:100%;
    margin-left: -130px;
    transition-duration: 0.9s;
    z-index: 999;
    .text{

        font-size: 13.5px;
        width: 100%;
        line-height: 16px;
        height: 16px;
        padding: 0px;
        position: absolute;
        text-align: center;
        background-color: #fff;
        border-radius: 4px;
    }
    .switch{

        $swithHeigth: 40px;
        $radiusSwitch: 27px;
        width: 100px;
        height: $swithHeigth;
        background-color: #fff;
        position: absolute;
        top: 100%; 
        margin-top: -35px;
        left: 50%;
        border-radius: $radiusSwitch;
        margin-left: -45px;
        box-shadow: 0px 3px 12px rgba(0,0,0,0.2);


        .circle{
            $p: 6px;
            $circleDimension: $swithHeigth+$p;

            width: $circleDimension+ $p/2;
            height: $circleDimension+ $p/2;
            transition-duration: 0.3s;
            //background-color: rgb(87, 156, 221);
            box-shadow: 0px 0px 23px rgb(87, 156, 221),
            0px 0px 23px rgb(87, 156, 221),
            0px 0px 23px rgb(87, 156, 221),
            ;
            position: absolute;
            margin-top: -$p/2;
            border-radius: 30px;
            &.left{
                margin-left: -$p;
                left:0%;
            }
            &.right{
                margin-left: -($circleDimension - $p);
                left:100%;
            }

        }

        .lang{
            width:$swithHeigth;
            height: $swithHeigth;
            border-radius: $radiusSwitch;
            background-color: #fff;
            position: absolute;
            transition-duration: 0.3s;
            background-size: contain;
            background-repeat: no-repeat;

            opacity:0.3;
            &.active{
                $p: 10px;
                height: $swithHeigth+$p;
                width: $swithHeigth+$p;
                margin-left: -$p/2;
                margin-top: -$p/2;
                opacity:1.0;
                transition-duration: 0.3s;
            }


            &.it{
                background-image: url(./it.png);
            }
            &.en{
                left:100%;
                margin-left: -$swithHeigth;
                background-image: url(./en.png);
            }
        }

    }
    &.fixed{
        transform: rotate(-90deg);
        margin-top: 40px;
        margin-left: -42px;
        transition-duration: 0.9s;
        &.open{
            transition-duration: 0.9s;
            transform: rotate(0deg);
        }
    }
}
