.footer{
    position: relative;
    z-index: 9;

    color:#444;
    background-color: transparent;
    padding: 10px 20px;
    margin-top: 200px;
    text-align: center;

    .footer-box{
        background-color: #333;
        display: inline-flex;
        border-radius: 13px;
        padding: 2px 4px;
        border: 1px solid #555;
        color:#fff;
        line-height: 30px;
        flex-direction: row;
        align-items: center;
        font-size: 12.2px;
    }
    .icon{
        &.react-love{
            width:30px;
            height: 30px;
            background-image: url(./love.svg);
            background-size: contain;
            display: inline-block;
            margin: 3px;
        }
    }
}