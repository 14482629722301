

.cv-page{
        max-width: 900px;


    .skill-cv{
   
        height: auto;
        width: 80%;
        display: grid;
        grid-template-columns: 30% 70%;
        border-bottom: 1px solid rgb(236, 236, 236);
        padding: 10px 20px;
        .icon{
            grid-column: 2;
            width:70px;
            height: 70px;
            margin:5px;
            border-radius: 50%;
            box-shadow: 0px 4px 24px rgba(0,0,0,0.12);
            background-size: 75% auto;
            background-position: center;
            background-repeat: no-repeat;
            -webkit-print-color-adjust: exact; 
            &.go{background-image: url(./assets/go.png);}
            &.flutter{background-image: url(./assets/flutter.png);}
            &.javascript{background-image: url(./assets/javascript.png);
            background-color: #f8dc3d;}
            &.python{background-image: url(./assets/python.png);
            background-color: #1e415d;}
        }
        .text-skill{
            grid-column: 2;
        }
    }

    .socials{
        .socialinfo{
            background-color: #d1d1d1e8;
            border-radius:  5px;
            box-shadow: 0px 3px 10px rgba(0,0,0,.1);
            padding:4px;
            margin:3px;
            display:inline-block;
            .icon{
                
                width:30px; height: 30px;
                -webkit-print-color-adjust: exact; 
                display: inline-block;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                &.git{
                    background-image: url(./assets/socials/github-logo.svg);
                }
                &.in{
                    background-image: url(./assets/socials/linkedin-icon-2.svg);
                }
                &.email{
                    background-image: url(./assets/socials/1280px-New_Logo_Gmail.svg.png);
                }
            }
             .link{
                 color: rgb(7, 90, 167);
                 margin-left: 10px;
                line-height: 25px;
                text-decoration: none;
             }  
        }
    }

    .gallery{
        .photo{

            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
            width: 300px; 
            height: 530px;
            display: inline-block;
            margin:6px;
            border-radius: 13px;
            -webkit-print-color-adjust: exact; 
            &.p1{
                background-image: url(./assets/app1.jpg);
            }
            &.p2{
                background-image: url(./assets/app2.jpg);
            }
        }
    }
}